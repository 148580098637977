.footer{
    bottom:0;
    width: 100%;
    background-color: #200713;
    padding: 1%;
    z-index: 0;
    flex-grow: 1;
    display: flex;
    position: relative;
    justify-content: center;
}

.footerText{
    color: white;
    text-align: left;
    float:left;
}

.footerContents
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.socialLinks{
    width: 0.625rem ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 10%;
}

.email{
    text-decoration: none;
    color: white;
    transition: 0.5s;
    float: right;
    
  

}

@media (max-width: 600px) {
    .footer{
        bottom:0;
        width: 100%;
        background-color: #200713;
        padding: 1%;
        z-index: 0;
        display: block;
       
    }
    .socialLinks{
        display: flex;
        position: relative;
        margin-top: 5%;
        text-align: left;
        float: left;
        
        
    }
    .footerText{
        text-align: none;
        float:none;
    }
    
}

a:hover{
    color: aqua;
    

}

