.media{
    width: 30%;
    height: auto;
    float:right;
    margin-right: 5%;
    margin-left: 5%;
    
}



.aboutModuleBak{

   background: #1D1F20;
    background-size: cover;
    min-height: 100vh;
    
}

.notFoundModule{
  background-image: url('../img/notfoundPage.gif');
  background-size: cover;  
  min-height: 100vh;
  
}


.aboutModule{
    background-color: white;
    padding-bottom: 2%;
   /* animation: 1s ease-out 0s 1 slideInFromTop; */
    z-index: 0;
    position: relative;
    
}
.projectPageHeader{
  padding: 2%;
}


.projectModule{
  background-color: white;
    height: 10%;
    position: relative;
    overflow-y: auto;
    padding-bottom: 1%;
    animation: fadein 2s;


}

@media (max-width: 600px) {
  .projectPageHeader{
  padding: 5%;
  size: 50%;
  
  }
}


@keyframes slideInFromTop {
    0% {
      transform: translateY(-300%);
   
    }
    100% {
      transform: translateY(0);
    }
  }
  