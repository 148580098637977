  .title{ 
    margin-bottom: 5%;
    background-color: aqua;
  }
  
  .media{
    border-radius: 10px;
    border: 3px solid #3f51b5;  
  }



.detailedView{
    background: #1D1F20;
    background-size: cover;
    min-height: 100vh;
}

.detailedView img{
  border: 3px solid black;
  border-radius: 2%;
  max-width: 100%;

}
  .projectBox{
    background-color: white;
    z-index: 0;
    max-width: 80%;
    position: relative;
    animation: fadein 2s;
    
    
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  table{
    width: 100%;
  }

  .loading{
    position: relative;
    color: white;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center
  
  }

  .descriptions {
    margin-left: 90%;
  }

  

  @media (max-width: 800px) {
    img {
      max-width: 90%;
      height: auto;
      float: none;
      border: 2px solid black;
      
        
    }
  }

  
  @keyframes slideInFromTop {
    0% {
      transform: translateY(-300%);
    }
    100% {
      transform: translateY(0);
    }
  }
  