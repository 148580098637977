.background {

    background-color: rgb(11, 11, 11);
    min-height: 100vh;
    padding-bottom: 20%;
    
}


.introCard{
        min-height: 800px;
        text-align: left;
        padding-top: 20%;
        
    
}

@media (max-width: 600px) {
    .background {
        background-image: url("../img/bakMobile.jpg");
        background-size: 600px 900px;
        background-repeat: no-repeat;
        background-color: rgb(11, 11, 11);
        padding-bottom: 20%;
    }

  
}