.navBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 10;
    margin-top: 100px;


}


.imageScale {
    height: 90px;
    margin-right: 50%;

}

.left {
    position: relative;
    color: white;
    position: relative;
    margin-right: 20%;
        text-decoration: none;
    font-size: 20px;
    align-content: left;
    font-family: Kanit ,sans-serif;
    transition: color 0.5s;
}

.left:before {

    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.left:hover:before {
    visibility: visible;
    width: 100%;
  }


.navItems {
    text-align: justify;
    align-content: right;
    margin-bottom: 20%;
    height: 100%;

}